// 密码登录子组件
<template>
  <div class="login-password">
    <el-form ref="passwordForm" :model="passwordForm">
      <el-input
        v-model="passwordForm.tel"
        placeholder="请输入常用手机号"
        style=" border-bottom: 1px solid #e0e6ed;"
      ></el-input>
      <div class="get-item">
        <el-input
          type="password"
          v-model="passwordForm.password"
          placeholder="请输入密码"
          style=" border-bottom: 1px solid #e0e6ed;"
        ></el-input>
        <span class="get" @click="forgetPassword">忘记密码?</span>
      </div>
      <el-button class="login" @click="login">登录</el-button>
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      passwordForm: {
        tel: "",
        password: ""
      }
    };
  },
  methods: {
    forgetPassword() {
      this.$emit("forgetPassword");
    },
    login() {
      this.$emit("codeLogin", this.passwordForm);
    }
  }
};
</script>
<style lang="less" >
.login-password {
  width: 290px;
  margin: 0px auto;
  .get-item {
    position: relative;
    .get {
      font-size: 13px;
      position: absolute;
      cursor: pointer;
      right: 1px;
      bottom: -30px;
    }
  }
  .el-input__inner {
    border: none;
    outline: none;
    padding: 10px 0px;
    margin-top: 10px;
    border-radius: 0;
  }
  .el-input__inner:focus {
    transition: 0.2s;
    border-bottom: 1px solid #40587a;
  }
  .login {
    margin-top: 56px;
    width: 100%;
    background-color: #40587a;
    color: aliceblue;
    font-size: 16px;
  }
}
</style>