<!-- 超管登录界面 -->
<template>
  <div id="super-login" v-loading="loading" element-loading-text="正在登陆中">
    <div class="login-view">
      <h1 style="text-align: center; margin-bottom:40px;">超管登录界面</h1>
      <div class="login-input">
        <div class="main-input">
          <el-button
            type="text"
            v-for="(item,i) in inputs"
            :key="i"
            :class="{'active':currentIndex==i  }"
            @click="TabClick(i)"
          >{{item}}</el-button>
        </div>
        <!-- 验证码登录子组件 -->
        <verificationLogin v-if="currentIndex==0" />
        <!-- 密码登录子组件 -->
        <codeLogin
          v-else-if="currentIndex==1"
          @codeLogin="codeLoginData"
          @forgetPassword="()=>{this.$router.push('/forgetPassword')}"
        />
      </div>
    </div>
  </div>
</template>
<script>
import verificationLogin from "@/components/superLogin/VerificationLogin";
import codeLogin from "@/components/superLogin/CodeLogin";
import * as validata from "@/utils/validata";
import { error, success } from "@/utils/notification";
export default {
  name: "login",
  components: { verificationLogin, codeLogin },
  data() {
    return {
      inputs: ["验证码登录", "密码登录"],
      currentIndex: 0,
      loading: false
    };
  },
  created() {
    let token = sessionStorage.getItem("token");
    if (token) {
      this.$router.push({
        path: "/organization"
      });
    }
  },
  methods: {
    TabClick(i) {
      this.currentIndex = i;
    }, //切换登录点击事件

    codeLoginData(passwordForm) {
      //子组件传父组件：密码登录的数据
      if (!validata.checkPhoneNumber(passwordForm.tel)) {
        error("请正确填写手机号码");
      } else if (validata.getNull(passwordForm.password)) {
        error("请正确填写密码");
      } else {
        this.$superHttp
          .post(this.URL.superUrl.superlogin, {
            mobile: passwordForm.tel,
            password: passwordForm.password
          })
          .then(res => {
            sessionStorage.superName = res.data.userInfo.nickname;
            sessionStorage.superToken = res.data.access_token;
            sessionStorage.user_id = res.data.userInfo.id;
            let hash = window.location.hash.split('?')[1]
            let tokenFlag = hash && hash.split('=')[1]
            if (tokenFlag) {
              // 从融创绵阳主系统的创业地图跳转过来
              this.$router.push("/mapIndex");
              success("登录成功");
              return false
            }
            this.$router.push("/superindex");
            success("登录成功");
          });
      }
    }
  }
};
</script>
<style lang="less" scoped>
#super-login {
  overflow: hidden;
  width: 650px;
  .login-input {
    box-shadow: 1px 1px 1px rgb(134, 127, 127);
    border-radius: 5px;
    background-color: white;
    height: 400px;
    padding: 40px;
    // text-align: center;
    .main-input {
      width: 290px;
      margin: 0 auto;
      margin-bottom: 30px;
      .active {
        padding-bottom: 6px;
        border-bottom: 2px #009eff solid;
        color: #009eff;
        border-radius: 0;
      }
    }
    .el-button--text {
      font-size: 16px;
      color: #a6a9cd;
    }
  }
}
</style>
