// 验证码登录子组件
<template>
  <div class="login-item">
    <el-form ref="verificationForm" :model="verificationForm">
      <el-input
        v-model="verificationForm.tel"
        placeholder="请输入常用手机号"
        style=" border-bottom: 1px solid #e0e6ed;"
      ></el-input>
      <div class="get-item">
        <el-input
          v-model="verificationForm.verification"
          placeholder="请输入验证码"
          style=" border-bottom: 1px solid #e0e6ed;"
        ></el-input>
        <span class="get" @click="getVer" v-show="verificationForm.is_getCode">获取验证码</span>
        <span
          class="get"
          v-show="!(verificationForm.is_getCode)"
        >{{verificationForm.codeTime}}s后重新获取</span>
      </div>
      <el-button class="login" @click="verificationLogin">登录/注册</el-button>
    </el-form>
  </div>
</template>
<script>
import * as validata from "@/utils/validata";
import { error, success } from "@/utils/notification";
export default {
  data() {
    return {
      verificationForm: {
        tel: "", //号码
        verification: "", //验证码
        is_getCode: true, //是否获取过验证码
        codeTime: 60 //验证码60s计时
      } //验证登录表单数据
    };
  },
  methods: {
    async getVer() {
      //验证码登录获取验证码
      if (!validata.checkPhoneNumber(this.verificationForm.tel)) {
        error("请正确输入手机号码");
      } else {
        const res = await this.$superHttp.post(this.URL.superUrl.sendSms, {
          mobile: this.verificationForm.tel
        });
        if (res.data.code === 200) {
          this.verificationForm.is_getCode = false;
          var timer = setInterval(() => {
            this.verificationForm.codeTime--;
          }, 1000);
          setTimeout(() => {
            clearInterval(timer);
            this.verificationForm.codeTime = 60;
            this.verificationForm.is_getCode = true;
          }, 60000);
        } else {
          // error(res.data.error_msg);
        }
      }
    },
    async verificationLogin() {
      //验证码登录
      // this.$emit('verificationLogin',this.verificationForm)
      if (!validata.checkPhoneNumber(this.verificationForm.tel)) {
        error("请正确输入手机号码");
      } else if (this.verificationForm.verification == "") {
        error("请输入验证码");
      } else {
        const res = await this.$superHttp.post(this.URL.superUrl.codeLogin, {
          mobile: this.verificationForm.tel,
          code: this.verificationForm.verification
        });
        sessionStorage.superToken = res.data.access_token;
        sessionStorage.superName = res.data.userInfo.nickname;
        this.$router.push("/organization");
        success("登录成功");
      }
    }
  }
};
</script>
<style lang="less"  >
.login-item {
  width: 290px;
  margin: 0px auto;
  .get-item {
    position: relative;
    .get {
      position: absolute;
      font-size: 13px;
      right: 5px;
      bottom: 15px;
      cursor: pointer;
    }
  }
  .el-input__inner {
    border: none;
    outline: none;
    padding: 10px 0px;
    margin-top: 10px;
    border-radius: 0;
  }
  .el-input__inner:focus {
    transition: 0.2s;
    border-bottom: 1px solid #40587a;
  }
  .login {
    margin-top: 56px;
    width: 100%;
    background-color: #40587a;
    color: aliceblue;
    font-size: 16px;
  }
}
</style>